import Footer from "../../components/Footer";
import Contactelemen from "../../components/contactelement";

function Contact(){
    return(
      <>
        <Contactelemen/>
        <Footer/>
      </>
    )
}

export default Contact;
